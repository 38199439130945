/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import axios from 'axios';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { needUpadte } from '../libraries/ApplicationService';

export const CORDOVA_VERSION = '2.1.80';

class ForceUpdate extends Component {
  state = {
    cordovaDialogOpen: false
  };

  componentDidMount() {
    if (window.cordova) {
      const url = `${
        import.meta.env.VITE_SERVER_PATH
      }/private/v1/updates/version`;
      axios
        .post(url, { platform: 'cordova' })
        .then(({ data }) => {
          if (needUpadte(data?.data?.content, CORDOVA_VERSION)) {
            this.setState({ cordovaDialogOpen: true });
          }
        })
        .catch(() => {});
    }
  }

  handleAppStore = () => {
    window.open('itms-apps://itunes.apple.com/app/1033297708', '_system');
  };

  render() {
    const { cordovaDialogOpen } = this.state;
    return (
      <Dialog open={cordovaDialogOpen}>
        <DialogTitle>New version required</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please update your app to the latest version to continue using
            GoGet.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleAppStore} color="primary">
            Go to App Store
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ForceUpdate;
